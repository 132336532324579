<template>
  <div>
    <!-- Start Header Area -->
    <Header>
      <img slot="logo" src="@/assets/images/logo/logo.png" />
    </Header>
    <!-- End Header Area -->


    <div class="rn-service-details rn-section-gap bg_color--1">
      <v-container>
        <v-row>
          <v-col lg="12">
            <div class="service-details-inner">
              <div class="inner">
                <!-- Start Single Content  -->
                <v-row
                  class="sercice-details-content pb--80 align-items-center"
                >
                  <v-col lg="12" md="12" cols="12">
                      <div class="details mt_md--30 mt_sm--30">
                          <div class="mt--120 mb--50 ml--50">
                              <h4>  Ερωτήσεις – Απαντήσεις </h4>

                          </div>

                          <div class="blog_details">

                              <h2>1. Τι είναι η εφαρμογή <i>ελίν</i> up επιβράβευση?</h2> </br>
                              <p>
                                  Είναι μια εφαρμογή που σας δίνει τη δυνατότητα αγοράς καυσίμων, προϊόντων και υπηρεσιών από τα πρατήρια <b style="color: #104788;"><i>ελίν</i></b>  που συμμετέχουν στο πρόγραμμα επιβράβευσης.
                                  Δημιουργήστε το προσωπικό σας προφίλ, κάντε τις αγορές σας, συλλέξτε πόντους και κερδίστε μοναδικά δώρα. Εύκολα και απλά και με ασφάλεια από το κινητό σας τηλέφωνο επιλέγετε
                                  το πρατήριο <b style="color: #104788;"><i>ελίν</i></b>  που σας εξυπηρετεί και προϊόν που επιθυμείτε και κάνετε την αγορά σας με ένα κλίκ!
                              </p>
                          </div>
                          <div class="blog_details">

                              <h2>2. Ποια είναι τα πλεονεκτήματα της αγοράς καυσίμων online?</h2> </br>
                              <p>
                                  Προαγοράζοντας καύσιμα για το αυτοκίνητό σας online
                                  κερδίζετε διπλάσιους πόντους <b style="color: #104788;"><i>up</i></b> επιβράβευση
                                  κερδίζετε χρόνο στο πρατήριο αφού δεν απαιτείται εκεί καμία άλλη συναλλαγή πέρα από τον ανεφοδιασμό σας καθώς ο υπάλληλος επαληθεύει τη συναλλαγή από την πινακίδα σας
                                  μπορείτε να αγοράσετε τα καύσιμα ή άλλα προϊόντα και υπηρεσίες του πρατηρίου <b style="color: #104788;"><i>ελίν</i></b>  που σας εξυπηρετεί για κάποιον δικό σας που βρίσκεται σε άλλη πόλη.
                              </p>
                          </div>
                          <div class="blog_details">

                              <h2>3. Τι είδους πληρωμές δέχεται η εφαρμογή?</h2> </br>
                              <p>
                                  Η εφαρμογή δέχεται πληρωμές από όλες τις πιστωτικές, χρεωστικές ή προπληρωμένες κάρτες VISA & MASTERCARD. Κάρτες MAESTRO και DINERS δεν γίνονται δεκτές.
                              </p>
                          </div>
                          <div class="blog_details">

                              <h2>4. Πως θα λάβω την απόδειξη αν έχω αγοράσει online?</h2> </br>
                              <p>
                                  Σας την παραδίδει ο υπάλληλος του πρατηρίου μόλις το επισκεφθείτε για να παραλάβετε το καύσιμο ή το άλλο προϊόν ή υπηρεσία που αγοράσατε.
                              </p>
                          </div>
                          <div class="blog_details">

                              <h2>5. Πότε αρχίζω να συλλέγω πόντους?</h2> </br>
                              <p>
                                  Από την πρώτη στιγμή! Μόλις εγγραφείτε στο <b style="color: #104788;"><i>up</i></b> επιβράβευση είτε από την εφαρμογή, είτε από το <a href="https://www.elin.gr">www.elin.gr</a>
                                  είτε συμπληρώνοντας την αίτηση σε πρατήριο <b style="color: #104788;"><i>ελίν</i></b>  οι πρώτοι 500 πόντοι είναι δώρο από την <b style="color: #104788;"><i>ελίν</i></b>  για εσάς πριν καν κάποια αγορά σας.
                              </p>
                          </div>
                          <div class="blog_details">

                              <h2>6. Λήγουν οι πόντοι μου?</h2> </br>
                              <p>
                                  Οι πόντοι που έχετε συγκεντρώσει λήγουν αν δεν έχετε πραγματοποιήσει καμία συναλλαγή
                                  σε πρατήριο <b style="color: #104788;"><i>ελίν</i></b>  τους τελευταίους 12 μήνες ή αν δεν έχει γίνει καμία εξαργύρωση πόντων για 8 έτη.
                              </p>
                          </div>
                          <div class="blog_details">

                              <h2>7. Πως μπορώ να δω το ιστορικό των αγορών και των εξαργυρώσεων μου και τους πόντους μου?</h2> </br>
                              <p>
                                  Στο μενού της εφαρμογής <b style="color: #104788;"><i>ελίν</i></b>  up επιβράβευση μπορείτε να επιλέξετε
                                  Το προφίλ μου και να δείτε το ιστορικό των αγορών σας ενώ επιλέγοντας
                                  Πόντοι εμφανίζονται οι πόντοι που έχετε συγκεντρώσει ως τώρα.
                                  Εναλλακτικά, μπορείτε να συνδεθείτε στο προφίλ σας από το <a href="https://www.elin.gr"> www.elin.gr</a> και να δείτε όλο το ιστορικό σας και τους διαθέσιμους πόντους σας.
                              </p>
                          </div>
                          <div class="blog_details">

                              <h2>8. Πως μπορώ να εξαργυρώνω τους πόντους μου?</h2> </br>
                              <p>
                                  Μέσα από την εφαρμογή <b style="color: #104788;"><i>ελίν</i></b> up επιβράβευση ανατρέχετε στον Κατάλογο Δώρων και επιλέγετε το δώρο της αρεσκείας σας ανάλογα με τους πόντους που επιθυμείτε να εξαργυρώσετε.
                                  Δηλώνετε τα στοιχεία αποστολής και το ονοματεπώνυμο σας και εντός 15 ημερών παραλαμβάνετε το δώρο σας χωρίς καμία χρέωση μεταφορικών στο χώρο σας.
                                  Ομοίως, από το  <a href="https://www.elin.gr"> www.elin.gr</a>
                                  επιλέγετε το δώρο που θέλετε από αυτά που αντιστοιχούν στους πόντους που έχετε συλλέξει και συμπληρώνετε τα στοιχεία για την αποστολή.
                              </p>
                          </div>
                          <div class="blog_details">

                              <h2>9. Πως μπορώ να δω τα διαθέσιμα δώρα?</h2> </br>
                              <p>
                                  Τα δώρα βρίσκονται στο έντυπο Κατάλογος Δώρων της <b style="color: #104788;"><i>ελίν</i></b> .
                                  Ωστόσο, επειδή οι διαθεσιμότητες αλλάζουν μπορείτε να βλέπετε την πιο πρόσφατη λίστα δώρων στην εφαρμογή Roadcube ή στο
                                  <a href="https://www.elin.gr"> www.elin.gr</a>.
                              </p>
                          </div>
                          <div class="blog_details">

                              <h2> 10. Πως μπορώ να επιλέξω την Άμεση εξαργύρωση των πόντων μου σε πρατήριο <b style="color: #104788;"><i>ελίν</i></b> ?</h2> </br>
                              <p>
                                  Από την εφαρμογή <b style="color: #104788;"><i>ελίν</i></b> up επιβράβευση ή από το  <a href="https://www.elin.gr"> www.elin.gr</a> επιλέγω την εξαργύρωση και τοποθετώντας την μπάρα στον αριθμό των πόντων που θέλετε να εξαργυρώσετε προκύπτει μια αντίστοιχη
                                  Δωροεπιταγή σε € με έναν κωδικό που μπορεί να εξαργυρωθεί σε αγορές που θα κάνετε σε όποιο από τα πρατήρια <b style="color: #104788;"><i>ελίν</i></b>  που συμμετέχουν στο πρόγραμμα επιθυμείτε.
                                  Απαραίτητη προϋπόθεση είναι οι αγορές σας να είναι ίσης ή μεγαλύτερης αξίας με τη Δωροεπιταγή που δεν ανταλλάσσεται με χρήματα.
                              </p>
                          </div>

<div class="blog_details">

  <h2> 11. Τι ακριβώς είναι η νέα δυνατότητα εξαργύρωσης 500 πόντων Crystal σε δωροεπιταγή 5€? </h2> </br>
  <p>
    Είναι μια νέα μοναδική δυνατότητα που προσφέρει το <b>ελίν up επιβράβευση</b> για να κάνει την εξαργύρωση πιο άμεση από ποτέ! 
    Από τις 29/10 μόλις συγκεντρώσετε 500 πόντους που έχετε συλλέξει αποκλειστικά από καύσιμα <b>Crystal</b> έχετε τη δυνατότητα να 
    τους εξαργυρώσετε σε δωροεπιταγή ελίν αξίας 5€! Δηλαδή δωροεπιταγή 5€ μέχρι να πεις… <b>Crystal! </b>
  </p>
  </div>

<div class="blog_details">

  <h2> 12. Πώς μπορώ να δω πόσους πόντους Crystal έχω συλλέξει μέσα στην εφαρμογή ελίν up επιβράβευση;  </h2> </br>
  <ul style="  list-style-type: circle;">
  <li>
    Στο κινητό σας τηλέφωνο, ανοίγοντας την εφαρμογή <b>ελίν up επιβράβευση</b>, στην κεντρική οθόνη θα υπάρχει πλέον ένα ξεχωριστό πεδίο που θα μετράει τους πόντους <b>Crystal</b>. 
    Εκεί θα προστίθενται οι πόντοι που κερδίζετε κάθε φορά που ανεφοδιάζετε το όχημα σας με καύσιμα <b>Crystal</b> 
  </li>
  <li>
    Το πεδίο που εμφανίζονταν οι συνολικοί σας πόντοι θα συνεχίσει να υπάρχει και σε αυτό θα προστίθενται οι πόντοι <b>από κάθε αγορά σας</b> στα πρατήρια <b>ελίν</b>.
    Δηλαδή εκεί θα προστίθενται οι πόντοι που κερδίζετε από απλά καύσιμα, από καύσιμα <b>Crystal</b> ή άλλα προϊόντα/υπηρεσίες 

  </li>
</ul>
  <v-img class="ma-5" src="@/assets/images/blog/q&a12.png" contain height="165"></v-img>
  <p>
    Άρα: Οι <b>πόντοι Crystal</b> είναι μέρος και των συνολικών πόντων αλλά θα τους βλέπετε πλέον και ξεχωριστά για να γνωρίζετε πόσο γρήγορα θα φτάσετε τους 500 ώστε να μπορείτε να κάνετε εξαργύρωση! 

  </p>
</div>

<div class="blog_details">

<h2> 13. Αλλάζει κάτι στη διαδικασία εξαργύρωσης των πόντων στο ελίν up επιβράβευση?   </h2> </br>
<p>Όχι, δεν αλλάζει κάτι στη διαδικασία εξαργύρωσης των πόντων. Η διαδικασία παραμένει όπως την γνωρίζετε: </p>
<p>
<ol style="  list-style-type: lower-roman; list-style-position: inside;">
<li>
  <span class="pl-7">Αγοράζετε καύσιμα ή άλλα προϊόντα από τα πρατήρια <b>ελίν</b> και συλλέγετε πόντους </span>
</li>
<li>
  <span class="pl-6">Επιλέγετε πως θέλετε να εξαργυρώσετε τους πόντους που έχετε συγκεντρώσει από τον Κατάλογο Δώρων που βρίσκεται στην εφαρμογή</span> </li>
<li>
  <span class="pl-5">Οι συνολικοί πόντοι μπορούν να εξαργυρωθούν σε Δώρα ή Δωροεπιταγές ελίν </span>

  </li>
<li>
  <span class="pl-5">Οι πόντοι Crystal αν έχουν φτάσει τους 500 μπορούν να εξαργυρωθούν μόνο στη νέα δωροεπιταγή που ονομάζεται Crystal 5€ </span>
</li>
</ol>
</p>
</div>


<div class="blog_details">

<h2> 14. Πώς μπορώ να μάθω πόσους πόντους έχω συλλέξει, <u>αν δεν</u> έχω πρόσβαση στην εφαρμογή ελίν up επιβράβευση; </h2> </br>
<ul >
<li>
  Κανένα πρόβλημα! Σε <b>οποιοδήποτε πρατήριο ελίν που συμμετέχει στο πρόγραμμα επιβράβευσης. </b>
  Δώστε τον αριθμό της <b>κάρτας ελίν up</b> επιβράβευση ή τον αριθμό του <b>κινητού</b> σας και οι συνεργάτες μας πρατηριούχοι θα σας ενημερώσουν για τους πόντους που έχετε συλλέξει, ή 
</li>
<li>
  Μπορείτε να κάνετε εγγραφή στο <a href="https://www.elinup.gr">www.elinup.gr</a> για να βλέπετε τους πόντους σας εύκολα και γρήγορα, ή 
</li>
<li>
  Μπορείτε να καλέσετε την <b>Εξυπηρέτηση Μελών</b> του <b>Προγράμματος ελίν up επιβράβευση</b> στο <b>210-3006633</b>, καθημερινά από <b>10:00 έως 18:00</b>, για να ενημερωθείτε για τους πόντους που έχετε συγκεντρώσει. 
</li>
</ul>
</div>

<div class="blog_details">

<h2> 15. Πώς μπορώ να μάθω πόσους πόντους έχω συλλέξει, <u>αν δεν</u> έχω πρόσβαση στην εφαρμογή ελίν up επιβράβευση; </h2> </br>
<p>
  Μόλις οι <b>πόντοι Crystal</b> που έχετε συλλέξει φτάσουν τους <b>500</b>, θα έχετε τη δυνατότητα να τους εξαργυρώσετε στη <b>νέα δωροεπιταγή Crystal 5€</b> για αγορές σε προϊόντα ή υπηρεσίες από τα πρατήρια <b>ελίν</b> που είναι συμβεβλημένα με το πρόγραμμα. 
</p>
<ul>
<li>
  Η νέα δωροεπιταγή θα είναι διαθέσιμη προς εξαργύρωση <b>μόνο αν έχετε συγκεντρώσει 500 πόντους Crystal και τουλάχιστον 500 συνολικούς πόντους. </b>
</li>
</ul>
<p>
  <b>
    Αν επιλέξετε την εξαργύρωση, οι πόντοι θα αφαιρεθούν και από τους συνολικούς πόντους σας, καθώς και από τους πόντους Crystal. 
  </b>
  <p class="pl-7 pr-7">
    Για την εξαργύρωση της δωροεπιταγής Crystal, επιλέγετε <b> Κατάλογος Δώρων &rarr; Εξαργύρωση σε ευρώ</b> και βλέπετε τη <b>δωροεπιταγή Crystal 5€.</b>  
     <i> Εκεί εμφανίζεται 500 Crystal / 500 πόντοι, που σημαίνει ότι για να την εξαργυρώσετε θα πρέπει να έχετε συγκεντρώσει 500 πόντους Crystal και να έχετε και 500 πόντους από τους συνολικούς.
     Αν οι πόντοι επαρκούν προχωράει η εξαργύρωση. Αν αυτή η προϋπόθεση δεν υφίσταται τότε θα δείτε το μήνυμα <b>«Δεν έχετε αρκετούς πόντους για να εξαργυρώσετε το δώρο»</b></i>
    </br><span style="color:rgb(216, 12, 12)">Σημαντικό: αν οι συνολικοί πόντοι σας είναι λιγότεροι από 500, η επιλογή για εξαργύρωση στη δωροεπιταγή Crystal δεν θα είναι διαθέσιμη. </span>
</p>
</p>
<v-img class="ma-5" src="@/assets/images/blog/q&a15.png" contain height="400"></v-img>
  <p style="text-align: center;">
   <b> Αν έχετε</b> τους απαραίτητους πόντους για να προχωρήσετε:
  </p>
  <p style="text-align: center;">
   <b> Ποια είναι τα επόμενα βήματα;</b>
  </p>
  <div class="pl-6 pl-md-16 pr-md-7 ml-md-16pt-4 pb-4 d-flex justify-center flex-column align-center">
  <ol style="">
<li>
  <span class="pl-2"> Εμφανίζεται ένα μήνυμα στην οθόνη για να επιβεβαιώσετε ότι θέλετε να προχωρήσετε με εξαργύρωση.  </span>
</li>
<li>
  <span class="pl-2"> <b>Επιλέγετε</b> "Συνέχεια" και λαμβάνετε με sms τον 12ψήφιο κωδικό. </span> 
</li>
<li>
  <span class="pl-2"><b> Δίνετε αυτόν τον κωδικό</b> στο πρατήριο. </span>

  </li>
<li>
  <span class="pl-2"> <b>Το πρατήριο προχωρά με τις δικές του ενέργειες</b>  </span>
</li>
</ol>
<div class="d-flex flex-column pl-md-8" style="width: 100%;">
<p class="pl-md-16 ml-n5">
  <b>Πόσο καιρό είναι ενεργός ο κωδικός;</b> </br>
  Ο κωδικός είναι ενεργός για 90 ημέρες.
</p>
<p class="pl-md-16 ml-n5">
  <b>Τι συμβαίνει αν δεν επισκεφθεί το πρατήριο εντός 90 ημερών;</b> </br>
  Αν δεν επισκεφθεί το πρατήριο, ο κωδικός λήγει και οι πόντοι επιστρέφονται
</p>
</div>
</div>

</div>

<div class="blog_details">

<h2> 16. Ποιά η διαφορά της Crystal δωροεπιταγής 5€ από την απλή δωροεπιταγή των 5€ που υπάρχει έως σήμερα?  </h2> </br>
<ul>
<li>
   Η Crystal δωροεπιταγή είναι ξεχωριστή δωροεπιταγή, καθώς έχει δημιουργηθεί <b>ειδικά για τους πόντους Crystal</b> και <b>εξαργυρώνεται μόνο με Crystal πόντους. </b>
</li>
<li>
   Κατά τα άλλα δεν έχει καμία διαφορά και μπορείτε αυτά τα 5€ να τα χρησιμοποιήσετε για <u>οποιαδήποτε</u> αγορά στα πρατήρια ελίν 
</li>

</ul>
</div>


<div class="blog_details">

<h2> 17. Αν θέλω να εξαργυρώσω τους πόντους μου σε κάποιο δώρο και όχι σε δωροεπιταγή μπορώ να το κάνω?  </h2> </br>
<p>
  Φυσικά! Εσείς επιλέγετε πως θα εξαργυρώσετε τους πόντους που συγκεντρώνετε από τις αγορές σας! 
  Τους <b>συνολικούς</b> πόντους που συγκεντρώνετε από τις αγορές σας μπορείτε να τους διαθέσετε όπως επιθυμείτε για αυτό και το ελίν up είναι το πιο ευέλικτο πρόγραμμα επιβράβευσης! 
</p>
<p>
  Απλά πλέον έχετε μια ακόμα επιλογή ειδικά για τους πόντους που συγκεντρώνετε από καύσιμα Crystal! Κορυφαία επιλογή σε καύσιμα…ακόμα πιο άμεση επιβράβευση! 
</p>
</div>


<div class="blog_details">

<h2> 18. Αν θέλω να εξαργυρώσω τους πόντους μου σε κάποιο δώρο και όχι σε δωροεπιταγή μπορώ να το κάνω?  </h2> </br>
<p>
  Όχι δεν λήγουν! Μπορείτε όταν φτάσετε τους 500 να τους εξαργυρώσετε αμέσως σε δωροεπιταγή αξίας 5€ αλλά μπορείτε να περιμένετε μέχρι να δικαιούστε παραπάνω από μια δωροεπιταγές! 
</p>

</div>



</div>
</v-col>
                </v-row>
                <!-- End Single Content  -->

              </div>
            </div>
          </v-col>
        </v-row>
        <p class="pl-7">
Επικοινωνήστε με την εξυπηρέτηση πελατών ελίν up επιβράβευση 
</p>
      </v-container>
    </div>

    <Footer />
  </div>
</template>

<script>
  import Header from "@/components/header/Header";
  import Footer from "@/components/footer/FooterTwo";
  export default {
    components: {
      Header,
      Footer,
    },
    data() {
      return {
        items: [
          {
            thumb: require("@/assets/images/service/service-02.png"),
            src: "https://www.youtube.com/watch?v=ZOoVOfieAF8",
          },
        ],
        index: null,
      };
    },

    methods: {},
  };
</script>
<style scoped>
  .container {
    max-width: 1600px;
  }
  h2 {
    font-size: 24px;
    margin-bottom: 15px;
    font-weight: 600;
    margin-bottom: 8px;
  }
  p, ul, li {
    font-family: "Poppins", sans-serif;
    color: #134075 !important;
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 15px;
    font-weight: normal;
  }
li::marker {
  font-size: 20px;
}
  .blog_details {
    padding: 60px 100px 60px 60px !important;
    box-shadow: 0px 10px 20px 0px rgb(221 221 221 / 30%);
  }
  @media only screen and (max-width: 600px) {
    .blog_details {
      padding: 10px 10px 10px 10px !important;
    }
  }
</style>
